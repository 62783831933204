import * as React from 'react'
import withoutHydration from 'react-without-hydration'
import { GetServerSidePropsContext } from 'next'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import { withCacheConfiguration } from '@thg-commerce/enterprise-core'
import { EnterpriseNextPage } from '@thg-commerce/enterprise-core/src/App/types'
import { PageCacheConfiguration } from '@thg-commerce/enterprise-core/src/cache/configuration'
import { loadConfiguration } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/ConfigurationLoader'
import { NextConfig } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { createApolloWithRequest } from '@thg-commerce/enterprise-core/src/Factory/apollo'
import { ContentHeaderLayout } from '@thg-commerce/enterprise-core/src/Layouts'
import { EnterpriseRequest } from '@thg-commerce/enterprise-core/src/Server/types'
import { NetworkClient } from '@thg-commerce/enterprise-network'
import { HomePage as HomePageResolver } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Content/HomePage'
import { clientConfig as clientConfigFactory } from '@thg-commerce/enterprise-network/src/ApolloProvider/utils'
import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'
import {
  CategorySetItemProps,
  CategorySetProps,
} from '@thg-commerce/enterprise-widget-category-set'
import { PaddingInterface } from '@thg-commerce/enterprise-widget-editorial/theme'
import { Separator } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'

import { FourUp, OriginalPostDataProps } from '../../src/components/FourUp'
import { RecentPosts } from '../../src/components/RecentPosts'
import {
  BlogNavigation,
  loadBlogNavigation,
} from '../../src/fetchers/blogNavigation'
import { HomePage as HOMEPAGE_QUERY } from '../../src/graphql/HomePage.graphql'
import {
  Author,
  AuthorCarouselRenderer,
} from '../../src/renderer/AuthorCarouselRenderer'
import { HomeMetaRenderer } from '../../src/renderer/Head/home/HomeMetaRenderer'

const CategorySetWidget = dynamic<CategorySetProps>(() =>
  import('@thg-commerce/enterprise-widget-category-set').then(
    (mod) => mod.CategorySet,
  ),
)
const CategorySetWidgetComponent = withoutHydration()(CategorySetWidget)

interface ContentCategorySetProps {
  title: string
  categories: {
    title: string
    imageAlt: string
    link: string
    imageSrc: string
  }[]
}

interface HomePageProps {
  blogNavigation: BlogNavigation
  hasWebP: boolean
  isAmp: boolean
  data: {
    pageSeo: PageSeoProps
    contentHome: {
      authors: Author[]
      featuredPosts: OriginalPostDataProps
      categorySetWidget: CategorySetProps
    }
    contentRoutePrefix: string
    cononicalUrl: string
  } | null
}

export interface PageSeoProps {
  metaDesc: string
  opengraphSiteName: string
  opengraphType: string
  opengraphUrl: string
  title: string
  fullURL: string
  structuredDataJsonObject: string
  metaRobotsNoindex: string
  metaRobotsNofollow: string
}

const StyledGrid = styled(Grid)`
  max-width: ${(props) => props.theme.site.siteWidth};
  padding: 0 ${spacing(4)};
  margin: 0 auto ${spacing(6)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: 0;
  }
`

const RecentPostsGridItem = styled(GridItem)`
  margin: 0 ${spacing(2)} ${spacing(5)};
`

const FourUpGridItem = styled(GridItem)`
  margin: 0;
`

const StyledSeparator = styled(Separator)`
  margin-bottom: ${spacing(2)};
  padding-left: ${spacing(4)};
  padding-right: ${spacing(4)};
`

const noGutterPaddingOrMarging: PaddingInterface = {
  top: [0, 0, 0, 0],
  right: [0, 0, 0, 0],
  bottom: [0, 0, 0, 0],
  left: [0, 0, 0, 0],
}

const buildCategorySetItems = (
  categorySetWidget: ContentCategorySetProps,
  canonical: string,
): CategorySetItemProps[] => {
  return categorySetWidget.categories.map((item) => {
    return {
      horizontalAlignment: 'center',
      picture: {
        sources: [{ sourceSet: item.imageSrc }],
        default: item.imageSrc,
        alt: item.imageAlt,
      },
      contentGutterPadding: noGutterPaddingOrMarging,
      lazy: true,
      buttonContent: item.title,
      buttonLink: `${canonical}${item.link}`,
    }
  })
}

const buildCategorySetProps = (
  categorySetWidget: ContentCategorySetProps,
  canonical: string,
): CategorySetProps => {
  return {
    titleProps: {
      title: categorySetWidget?.title || '',
    },
    gutterPadding: noGutterPaddingOrMarging,
    gutterMargin: noGutterPaddingOrMarging,
    items:
      categorySetWidget?.categories.length > 0
        ? buildCategorySetItems(categorySetWidget, canonical)
        : [],
    container: {
      itemAlignment: 'center',
      gap: {
        row: [3, 3, 3, 3],
        column: [3, 3, 3, 3],
      },
    },
    numberOfColumns: 3,
  }
}

const Home: EnterpriseNextPage<HomePageProps> = (props) => {
  const { data } = props

  const excludedPosts = [
    data?.contentHome?.featuredPosts.largePost.databaseId || 0,
    data?.contentHome?.featuredPosts.mediumPost.databaseId || 0,
    data?.contentHome?.featuredPosts.smallOnePost.databaseId || 0,
    data?.contentHome?.featuredPosts.smallTwoPost.databaseId || 0,
  ]

  if (data) {
    const homeMetaRendererProps = {
      seo: {
        metaDesc: data.pageSeo.metaDesc,
        metaRobotsNoindex: data.pageSeo.metaRobotsNoindex,
        metaRobotsNofollow: data.pageSeo.metaRobotsNofollow,
      },
      canonicalUrl: `${data.cononicalUrl}/`,
    }

    const categorySetWidgetProps =
      data.contentHome.categorySetWidget || undefined

    return (
      <React.Fragment>
        <Head>
          <title>{data.pageSeo?.title}</title>
          {HomeMetaRenderer(homeMetaRendererProps)}
          {data.pageSeo?.structuredDataJsonObject && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: data.pageSeo.structuredDataJsonObject,
              }}
            />
          )}
        </Head>

        <StyledGrid>
          <FourUpGridItem colSpan={[12, 12, 12, 12]} colStart={[1, 1, 1, 1]}>
            <FourUp {...data.contentHome?.featuredPosts} />
            <StyledSeparator />
          </FourUpGridItem>

          {data.contentHome?.authors?.length > 0 && (
            <GridItem colSpan={[12, 12, 10, 10]} colStart={[1, 1, 2, 2]}>
              <AuthorCarouselRenderer
                contentRoutePrefix={data.contentRoutePrefix}
                authors={data.contentHome.authors}
                isAmp={props.isAmp}
              />
            </GridItem>
          )}

          <RecentPostsGridItem colSpan={[12, 12, 6, 6]} colStart={[1, 1, 4, 4]}>
            <RecentPosts
              excludedPosts={excludedPosts}
              hideFirstSeparator={data.contentHome?.authors?.length === 0}
              alternativeImageStyle={true}
            />
          </RecentPostsGridItem>

          {categorySetWidgetProps.items.length > 0 && (
            <GridItem colSpan={[12, 12, 12, 12]} colStart={[1, 1, 1, 1]}>
              <CategorySetWidgetComponent {...categorySetWidgetProps} />
            </GridItem>
          )}
        </StyledGrid>
      </React.Fragment>
    )
  }

  return null
}

export const getServerSideProps = withCacheConfiguration<
  { props: HomePageProps },
  GetServerSidePropsContext
>(
  async (
    context: GetServerSidePropsContext,
  ): Promise<{ props: HomePageProps; cache?: PageCacheConfiguration }> => {
    const nextConfig = getConfig() as NextConfig
    const request = context.req as EnterpriseRequest
    const config = await loadConfiguration(nextConfig, request)
    const apollo = createApolloWithRequest(request, config, true)
    const { publicRuntimeConfig } = config
    const allowOverrides =
      request.headers['x-enterprise-allow-overrides'] === '1'
    const allowSitePropertyOverrides =
      request.headers['x-enterprise-allow-site-property-overrides'] === '1'
    const clientConfig = clientConfigFactory(
      publicRuntimeConfig,
      allowOverrides,
      allowSitePropertyOverrides,
      request.config.enableVary,
    )
    const { contentRoutePrefix, domain } = publicRuntimeConfig.siteDefinition
    const canonical = `https://${domain}/${contentRoutePrefix}`

    apollo.setResolvers({
      Query: {
        blogHome: HomePageResolver(apollo, clientConfig),
      },
    })

    const blogNavigation = await loadBlogNavigation(config, apollo)

    const { data } = await apollo.query({
      query: HOMEPAGE_QUERY,
      context: { clientName: NetworkClient.Content },
    })

    if (!data) {
      return {
        props: {
          blogNavigation,
          data: null,
          hasWebP: false,
          isAmp: false,
        },
      }
    }

    return {
      props: {
        blogNavigation,
        data: {
          ...data.blogHome,
          contentRoutePrefix,
          cononicalUrl: canonical,
          contentHome: {
            ...data.blogHome.contentHome,
            categorySetWidget: buildCategorySetProps(
              data.blogHome.contentHome?.categorySetWidget,
              canonical,
            ),
          },
        },
        hasWebP: false,
        isAmp: false,
      },
      cache: {
        ttl: 1800, // 30 minutes
        grace: 86400, // 1 Day
        config: {
          domain,
        },
      },
    }
  },
)

Home.Layout = ContentHeaderLayout

export default Home
