import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { useSiteDefinition } from '@thg-commerce/enterprise-core'

import {
  AsgardPost,
  RecentPostsArgs,
} from '@thg-commerce/enterprise-network/types/enterpriseTypes'

import { InfinitePosts } from '../InfinitePosts'
import { RecentPosts as RECENT_POSTS_QUERY } from '../../graphql/RecentPosts.graphql'

const POST_LIMIT = 5
interface RecentPostInterface {
  excludedPosts: number[]
  hideFirstSeparator?: boolean
  alternativeImageStyle?: boolean
}

export const RecentPosts = (props: RecentPostInterface) => {
  const { asgardToken, contentSlug } = useSiteDefinition()
  const { data: recentPostsData, refetch } = useQuery<
    { recentPosts: AsgardPost[] },
    RecentPostsArgs
  >(RECENT_POSTS_QUERY, {
    variables: {
      offset: 0,
      slug: contentSlug || '',
      token: asgardToken || '',
      limit: POST_LIMIT,
      exclude: props.excludedPosts,
    },
    fetchPolicy: 'no-cache',
  })

  const [offset, setOffset] = React.useState<number>(0)
  const [displayShowMore, setDisplayShowMore] = React.useState<boolean>(true)

  const [recentPosts, setRecentPosts] = React.useState<AsgardPost[]>(
    recentPostsData?.recentPosts || [],
  )

  React.useEffect(() => {
    if (
      recentPostsData?.recentPosts &&
      recentPostsData.recentPosts.length < POST_LIMIT
    ) {
      setDisplayShowMore(false)
    }

    recentPostsData?.recentPosts &&
      JSON.stringify(recentPosts) !==
        JSON.stringify(recentPostsData.recentPosts) &&
      setRecentPosts((existingPosts) => [
        ...existingPosts,
        ...recentPostsData.recentPosts,
      ])
  }, [recentPostsData])

  return (
    <InfinitePosts
      offset={offset}
      posts={recentPosts || []}
      refetch={(offset) => {
        refetch({
          offset,
          limit: POST_LIMIT,
          slug: contentSlug || '',
          token: asgardToken || '',
          exclude: props.excludedPosts || '',
        })
        setOffset(offset)
      }}
      postLimit={POST_LIMIT}
      displayShowMore={displayShowMore}
      isAmp={false}
      hideFirstSeparator={props.hideFirstSeparator}
      alternativeImageStyle={props.alternativeImageStyle}
    />
  )
}
