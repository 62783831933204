import { PageSeoProps } from '../../../../pages/blog'
import { MetaRenderer, RendererTags } from '../MetaRenderer'
import { getRobotsMeta } from '../utils'
import { HomeMetadataTransformer } from '../../../helpers/MetadataTransformer'

export interface MetaRendererSeoProps extends Partial<PageSeoProps> {
  metaDesc: string
  metaRobotsNoindex: string
  metaRobotsNofollow: string
}

export interface HomeMetaRendererProps {
  seo?: MetaRendererSeoProps
  canonicalUrl: string
}

export type HomeMetaNameType = {
  description?: string
  robots?: string
  googlebot?: string
  bingbot?: string
}

export type HomeMetaLinkType = {
  canonical: string
}

export interface HomeMetaTags extends Partial<RendererTags> {
  metaNameTags: HomeMetaNameType
  linkTags: HomeMetaLinkType
}

export interface PartialHomeMetaTags
  extends Omit<HomeMetaTags, 'metaNameTags' | 'linkTags'> {
  metaNameTags: Partial<HomeMetaNameType>
  linkTags: Partial<HomeMetaLinkType>
}

export const HomeMetaRenderer = (props: HomeMetaRendererProps) => {
  const homeMetaTags: HomeMetaTags = {
    metaNameTags: {
      robots:
        (props.seo?.metaRobotsNoindex &&
          props.seo.metaRobotsNofollow &&
          getRobotsMeta(
            props.seo?.metaRobotsNoindex,
            props.seo?.metaRobotsNofollow,
          )) ||
        undefined,
    },
    linkTags: {
      canonical: props.canonicalUrl,
    },
  }

  if (props.seo?.metaDesc) {
    homeMetaTags.metaNameTags.description = props.seo?.metaDesc
  }

  return MetaRenderer(
    HomeMetadataTransformer({
      metaNameTags: homeMetaTags.metaNameTags,
      linkTags: homeMetaTags.linkTags,
    }),
  )
}
